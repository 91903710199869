<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/question-type' }">
        <app-i18n
          code="entities.questionType.menu"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.questionType.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.questionType.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-question-type-view-toolbar
        v-if="record"
      ></app-question-type-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.questionType.label"
          :value="presenter(record, 'questionType')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.autoScore.label"
          :value="presenter(record, 'autoScore')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.section.label"
          :value="presenter(record, 'section')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.description.label"
          :value="presenter(record, 'description')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.title.label"
          :value="presenter(record, 'title')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionTypeViewToolbar from '@/modules/question-type/components/question-type-view-toolbar.vue';
import { QuestionTypeModel } from '@/modules/question-type/question-type-model';

const { fields } = QuestionTypeModel;

export default {
  name: 'app-question-type-view-page',

  props: ['id'],

  components: {
    [QuestionTypeViewToolbar.name]: QuestionTypeViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'questionType/view/record',
      loading: 'questionType/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'questionType/view/doFind',
    }),

    presenter(record, fieldName) {
      return QuestionTypeModel.presenter(record, fieldName);
    },
  },
};
</script>
